.dd {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &__value {
    position: relative;
    display: inline-flex;
    width: 300px;
    padding: 1rem 3rem 1rem 1rem;
    background: #fff;
    border-left: 2px solid transparent;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.3s ease-in-out;

    @include respond-to(small) {
      width: 280px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 9px;
      height: 9px;
      border-left: 2px solid var(--main-color, #0063c8);
      border-bottom: 2px solid var(--main-color, #0063c8);
      transform: rotate(-45deg);
    }
  }

  &__list {
    z-index: 2;
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    padding: 0.5rem 0;
    background: #fff;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;

    &.is-shown {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__option {
    padding: 1rem 3rem 1rem 2rem;
    white-space: nowrap;
    cursor: pointer;
    border-left: 2px solid transparent;

    &.is-active {
      border-left: 2px solid var(--main-color, #0063c8);
    }

    &:hover {
      color: var(--main-color, #0063c8);
    }
  }
}
