$break-small: 640px;
$break-medium: 768px;
$break-large: 992px;
$break-extra-large: 1200px;

@mixin respond-to($media) {
  @if $media == extra-small {
      @media only screen and (max-width: $break-small - 1) {
          @content;
      }
  }
  @if $media == small {
      @media only screen and (max-width: $break-medium - 1) {
          @content;
      }
  }
  @else if $media == medium {
      @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
          @content;
      }
  }
  @else if $media == large {
      @media only screen and (min-width: $break-large) and (max-width: $break-extra-large - 1) {
          @content;
      }
  }
  @else if $media == extra-large {
      @media only screen and (min-width: $break-extra-large) {
          @content;
      }
  }
}
