.loading {
  z-index: 9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
  }

  // &__inner{

  // }

  &__bar{
    margin: 0 2px;
    display: inline-block;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    animation: loading 1s ease-in-out infinite;

    &:nth-child(1) {
      background-color: #3498db;
      animation-delay: 0;
    }

    &:nth-child(2) {
      background-color: #3498db;
      animation-delay: 0.09s;
    }

    &:nth-child(3) {
      background-color: #3498db;
      animation-delay: .18s;
    }

    &:nth-child(4) {
      background-color: #3498db;
      animation-delay: .27s;
    }
  }

  &.is-hidden{
    animation: loader-hide .3s forwards ease-in-out;
    pointer-events: none;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1, 2.2);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes loader-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}