.btn {
  display: inline-flex;
  flex: 0 0 auto;
  padding: 1rem 2rem;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  // white-space: nowrap;

  @include respond-to(large) {
    padding: 0.8rem 1.5rem;
  }

  @include respond-to(medium) {
    padding: 0.8rem 1.5rem;
  }

  @include respond-to(small) {
    white-space: normal;
  }

  &--action {
    background: var(--main-color, #0063c8);
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  &--active {
    border-left: 3px solid var(--main-color, #0063c8);
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(43, 61, 78, 0.2);
  }
}
