@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap&subset=cyrillic");
@import "styles/components/media";
@import "styles/components/loader";
@import "styles/components/form";
@import "styles/components/btn";
@import "styles/components/dd";

body,
html {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  background: var(--page-bg, linear-gradient(47deg, #e3ffe7 0%, #d9e7ff 100%));

  @include respond-to(medium) {
    background: #fff;
  }

  @include respond-to(small) {
    background: #fff;
  }
}

* {
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  background: rgba(#fff, 0.95);

  @include respond-to(extra-large) {
    max-width: 1200px;
  }

  @include respond-to(large) {
    max-width: 1000px;
  }

  @include respond-to(medium) {
    max-width: auto;
    width: 100%;
    background: #fff;
  }

  @include respond-to(small) {
    max-width: auto;
    width: 100%;
    background: #fff;
  }
}

.header {
  padding: 2rem 3rem 3rem;

  @include respond-to(small) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include respond-to(extra-small) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__logo {
    height: 70px;

    img {
      height: 70px;
    }
  }

  &__title {
    font-size: 1.3rem;
  }

  &__branch {
    margin-top: 1rem;
    font-size: 1rem;

    span {
      font-weight: 500;
    }
  }
}

.steps {
  display: flex;
  margin-top: 3rem;

  @include respond-to(small) {
    margin-top: 1.5rem;
  }

  &__step {
    display: flex;
    padding-bottom: 0.4rem;
    align-items: flex-end;
    color: #8d8d8d;
    border-bottom: 2px solid transparent;

    &--active {
      color: #000;
      border-bottom: 2px solid var(--main-color, #0063c8);
      cursor: pointer;
    }

    &.is-disable {
      cursor: auto;
    }

    & + & {
      margin-left: 3rem;

      @include respond-to(small) {
        margin-left: 1.5rem;
      }

      @include respond-to(extra-small) {
        margin-left: 1rem;
      }
    }
  }

  &__count {
    margin-right: 5px;
    padding-bottom: 2px;
    font-size: 0.7rem;

    @include respond-to(extra-small) {
      display: none;
    }
  }

  &__title {
    font-size: 1.2rem;
    white-space: nowrap;

    @include respond-to(small) {
      font-size: 1rem;
    }

    @include respond-to(extra-small) {
      font-size: 0.9rem;
      white-space: normal;
    }
  }
}

.container {
  flex: 1;
  padding: 0 3rem 1rem;
  position: relative;

  @include respond-to(small) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include respond-to(extra-small) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.list {
  display: inline-flex;
  flex-direction: column;

  &--expanded {
    width: 100%;
  }
}

.footer {
  padding: 2rem 3rem 6rem;

  @include respond-to(small) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include respond-to(extra-small) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__action {
    display: flex;
    justify-content: space-between;
  }

  &__to-order {
    display: flex;
  }

  &__error {
    margin-right: 2rem;
    color: #5f5f5f;
  }

  &__terms {
    margin-top: 2.5rem;
    font-size: 0.9rem;

    a {
      color: var(--main-color, #0063c8);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.list-item {
  @for $i from 1 through 10 {
    &--sub-#{$i} {
      margin-left: #{($i * 30) + "px"};
    }
  }

  & + & {
    margin-top: 1rem;
  }
}

.list-filter {
  display: flex;
  margin-bottom: 3rem;

  @include respond-to(small) {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  > div {
    margin-right: 1rem;

    @include respond-to(small) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
}

.calendar {
  z-index: 9;
  position: sticky;
  top: 0;
  align-self: flex-start;
  padding: 4px;
  border: 0;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.1);

  &__tile:not(.react-calendar__tile--active):not(:disabled) {
    background: #e3ffe7;
  }
}

.react-calendar__tile {
  &--active:not(:hover):not(:focus) {
    // color: var(--main-color, #1087ff);
  }
}
.react-calendar__tile--active {
  color: #fff;
  background: var(--main-color, #1087ff);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--main-color, #1087ff);
  opacity: 0.9;
}

.columns {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  justify-content: space-between;
  grid-auto-rows: min-content;

  @include respond-to(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond-to(medium) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include respond-to(small) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond-to(extra-small) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.column-item {
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;

  &.is-disabled {
    background: rgb(235, 235, 235);
  }
}

.dateAndDoctor {
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;

  @include respond-to(medium) {
    grid-template-columns: 1fr;
  }

  @include respond-to(small) {
    grid-template-columns: 1fr;
  }

  @include respond-to(extra-small) {
    grid-template-columns: 1fr;
  }
}

.doctors {
  display: grid;
  grid-template-columns: 1fr;
  // grid-template-columns: repeat(2, 1fr);
  // grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: space-between;
  grid-auto-rows: min-content;

  @include respond-to(small) {
    grid-template-columns: 1fr;
  }

  @include respond-to(extra-small) {
    grid-template-columns: 1fr;
  }
}

.doctor {
  padding: 10px;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-areas: "img name" "img spec" "img desc";
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 1rem;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &__photo {
    grid-area: img;
    width: 150px;
    height: 150px;
    background: rgba(0, 0, 0, 0.05);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 150px;
    }
  }

  &__name {
    grid-area: name;
    font-size: 1.2rem;
  }

  &__specializations {
    grid-area: spec;
    margin-top: 0.8rem;
  }

  &__description {
    grid-area: desc;
    margin-top: 0.8rem;
    font-size: 0.7rem;
    color: #b5b5b5;
    white-space: pre-wrap;

    a {
      margin-left: 5px;
      color: var(--main-color, #0063c8);
    }
  }

  &--active {
    border-left: 3px solid var(--main-color, #0063c8);
  }

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(43, 61, 78, 0.2);
  }
}

.details {
  margin: 2rem 0 2rem;
  display: flex;

  @include respond-to(small) {
    flex-direction: column;
  }

  &__row {
    position: relative;
    margin-right: 3rem;

    @include respond-to(small) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    &--main {
      flex: 1;
    }

    // &+&{
    //   margin-top: 1.5rem;
    // }
  }

  &__label {
    font-size: 0.9rem;
    color: #686868;
  }

  &__data {
    margin-top: 0.5rem;
    font-size: 1.1rem;

    &--nowrap {
      white-space: nowrap;
    }
  }
}

.content-columns {
  display: flex;
}

.message {
  margin-top: 2rem;
  font-size: 1.1rem;
}

.result {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: #00836d;
}
