.form {
  position: relative;

  &__title {
    margin-bottom: 3rem;
    font-size: 1.1rem;
  }

  &__row {
    position: relative;

    & + & {
      margin-top: 3rem;
    }
  }

  &__control {
    display: inline-flex;
    flex: 0 0 auto;
    width: 300px;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-in-out;
    border: 0;
    outline: none;
    border-bottom: 1px solid #999999;
    margin: 0;
    font-family: "Ubuntu", sans-serif;
    color: #000;
    font-size: 16px;

    @include respond-to(small) {
      width: 280px;
    }

    @at-root textarea#{&} {
      width: 500px;
      resize: none;

      @include respond-to(extra-small) {
        width: 280px;
      }
    }

    &:focus::placeholder {
      opacity: 1;
    }

    &:focus::-ms-input-placeholder {
      opacity: 1;
    }

    &:focus {
      border-bottom: 1px solid var(--main-color, #0063c8);
    }
  }

  &__label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    transition: 0.3s ease-in-out;
    cursor: text;
  }

  @supports (-ms-ime-align: auto) {
    &__label {
      display: none;
    }
  }
  .form__control:not(:placeholder-shown) + .form__label,
  .form__control:focus + .form__label,
  .dd + .form__label {
    transform: translate(-0.8rem, -2.5rem);
    font-size: 0.8rem;
    color: #686868;
  }

  &--second-column {
    margin-top: 4rem;
    margin-left: 2rem;
  }

  &__comment {
    margin-top: 1rem;
    margin-left: 1rem;
    color: #686868;
  }

  &__doctor {
    margin-top: 6rem;
    max-width: 500px;
  }
}

::placeholder {
  opacity: 0;
  transition: inherit;
}
