@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap&subset=cyrillic);
.loading {
  z-index: 9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; }
  .loading::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff; }
  .loading__bar {
    margin: 0 2px;
    display: inline-block;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    -webkit-animation: loading 1s ease-in-out infinite;
            animation: loading 1s ease-in-out infinite; }
    .loading__bar:nth-child(1) {
      background-color: #3498db;
      -webkit-animation-delay: 0;
              animation-delay: 0; }
    .loading__bar:nth-child(2) {
      background-color: #3498db;
      -webkit-animation-delay: 0.09s;
              animation-delay: 0.09s; }
    .loading__bar:nth-child(3) {
      background-color: #3498db;
      -webkit-animation-delay: .18s;
              animation-delay: .18s; }
    .loading__bar:nth-child(4) {
      background-color: #3498db;
      -webkit-animation-delay: .27s;
              animation-delay: .27s; }
  .loading.is-hidden {
    -webkit-animation: loader-hide .3s forwards ease-in-out;
            animation: loader-hide .3s forwards ease-in-out;
    pointer-events: none; }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes loading {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1, 2.2);
            transform: scale(1, 2.2); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loader-hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes loader-hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.form {
  position: relative; }
  .form__title {
    margin-bottom: 3rem;
    font-size: 1.1rem; }
  .form__row {
    position: relative; }
    .form__row + .form__row {
      margin-top: 3rem; }
  .form__control {
    display: inline-flex;
    flex: 0 0 auto;
    width: 300px;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 0;
    outline: none;
    border-bottom: 1px solid #999999;
    margin: 0;
    font-family: "Ubuntu", sans-serif;
    color: #000;
    font-size: 16px; }
    @media only screen and (max-width: 767px) {
      .form__control {
        width: 280px; } }
    textarea.form__control {
      width: 500px;
      resize: none; }
      @media only screen and (max-width: 639px) {
        textarea.form__control {
          width: 280px; } }
    .form__control:focus::-webkit-input-placeholder {
      opacity: 1; }
    .form__control:focus::-moz-placeholder {
      opacity: 1; }
    .form__control:focus:-ms-input-placeholder {
      opacity: 1; }
    .form__control:focus::-ms-input-placeholder {
      opacity: 1; }
    .form__control:focus::placeholder {
      opacity: 1; }
    .form__control:focus::-ms-input-placeholder {
      opacity: 1; }
    .form__control:focus {
      border-bottom: 1px solid #0063c8;
      border-bottom: 1px solid var(--main-color, #0063c8); }
  .form__label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: text; }
  @supports (-ms-ime-align: auto) {
    .form__label {
      display: none; } }
  .form .form__control:not(:placeholder-shown) + .form__label,
  .form .form__control:focus + .form__label,
  .form .dd + .form__label {
    -webkit-transform: translate(-0.8rem, -2.5rem);
            transform: translate(-0.8rem, -2.5rem);
    font-size: 0.8rem;
    color: #686868; }
  .form--second-column {
    margin-top: 4rem;
    margin-left: 2rem; }
  .form__comment {
    margin-top: 1rem;
    margin-left: 1rem;
    color: #686868; }
  .form__doctor {
    margin-top: 6rem;
    max-width: 500px; }

::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit; }

::-moz-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit; }

:-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit; }

::-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit; }

::placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit; }

.btn {
  display: inline-flex;
  flex: 0 0 auto;
  padding: 1rem 2rem;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .btn {
      padding: 0.8rem 1.5rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .btn {
      padding: 0.8rem 1.5rem; } }
  @media only screen and (max-width: 767px) {
    .btn {
      white-space: normal; } }
  .btn--action {
    background: #0063c8;
    background: var(--main-color, #0063c8);
    color: #fff; }
    .btn--action:hover {
      opacity: 0.8; }
  .btn--active {
    border-left: 3px solid #0063c8;
    border-left: 3px solid var(--main-color, #0063c8); }
  .btn.is-disabled {
    opacity: 0.5;
    pointer-events: none; }
  .btn:hover {
    box-shadow: 0 2px 2px 0 rgba(43, 61, 78, 0.2); }

.dd {
  display: inline-flex;
  flex-direction: column;
  position: relative; }
  .dd__value {
    position: relative;
    display: inline-flex;
    width: 300px;
    padding: 1rem 3rem 1rem 1rem;
    background: #fff;
    border-left: 2px solid transparent;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
    @media only screen and (max-width: 767px) {
      .dd__value {
        width: 280px; } }
    .dd__value::after {
      content: "";
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 9px;
      height: 9px;
      border-left: 2px solid #0063c8;
      border-left: 2px solid var(--main-color, #0063c8);
      border-bottom: 2px solid #0063c8;
      border-bottom: 2px solid var(--main-color, #0063c8);
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .dd__list {
    z-index: 2;
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    padding: 0.5rem 0;
    background: #fff;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out; }
    .dd__list.is-shown {
      opacity: 1;
      pointer-events: auto; }
  .dd__option {
    padding: 1rem 3rem 1rem 2rem;
    white-space: nowrap;
    cursor: pointer;
    border-left: 2px solid transparent; }
    .dd__option.is-active {
      border-left: 2px solid #0063c8;
      border-left: 2px solid var(--main-color, #0063c8); }
    .dd__option:hover {
      color: #0063c8;
      color: var(--main-color, #0063c8); }

body,
html {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  background: -webkit-linear-gradient(43deg, #e3ffe7 0%, #d9e7ff 100%);
  background: linear-gradient(47deg, #e3ffe7 0%, #d9e7ff 100%);
  background: var(--page-bg, linear-gradient(47deg, #e3ffe7 0%, #d9e7ff 100%)); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    body,
    html {
      background: #fff; } }
  @media only screen and (max-width: 767px) {
    body,
    html {
      background: #fff; } }

* {
  box-sizing: border-box; }

.app {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.95); }
  @media only screen and (min-width: 1200px) {
    .app {
      max-width: 1200px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .app {
      max-width: 1000px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .app {
      max-width: auto;
      width: 100%;
      background: #fff; } }
  @media only screen and (max-width: 767px) {
    .app {
      max-width: auto;
      width: 100%;
      background: #fff; } }

.header {
  padding: 2rem 3rem 3rem; }
  @media only screen and (max-width: 767px) {
    .header {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem; } }
  @media only screen and (max-width: 639px) {
    .header {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .header__logo {
    height: 70px; }
    .header__logo img {
      height: 70px; }
  .header__title {
    font-size: 1.3rem; }
  .header__branch {
    margin-top: 1rem;
    font-size: 1rem; }
    .header__branch span {
      font-weight: 500; }

.steps {
  display: flex;
  margin-top: 3rem; }
  @media only screen and (max-width: 767px) {
    .steps {
      margin-top: 1.5rem; } }
  .steps__step {
    display: flex;
    padding-bottom: 0.4rem;
    align-items: flex-end;
    color: #8d8d8d;
    border-bottom: 2px solid transparent; }
    .steps__step--active {
      color: #000;
      border-bottom: 2px solid #0063c8;
      border-bottom: 2px solid var(--main-color, #0063c8);
      cursor: pointer; }
    .steps__step.is-disable {
      cursor: auto; }
    .steps__step + .steps__step {
      margin-left: 3rem; }
      @media only screen and (max-width: 767px) {
        .steps__step + .steps__step {
          margin-left: 1.5rem; } }
      @media only screen and (max-width: 639px) {
        .steps__step + .steps__step {
          margin-left: 1rem; } }
  .steps__count {
    margin-right: 5px;
    padding-bottom: 2px;
    font-size: 0.7rem; }
    @media only screen and (max-width: 639px) {
      .steps__count {
        display: none; } }
  .steps__title {
    font-size: 1.2rem;
    white-space: nowrap; }
    @media only screen and (max-width: 767px) {
      .steps__title {
        font-size: 1rem; } }
    @media only screen and (max-width: 639px) {
      .steps__title {
        font-size: 0.9rem;
        white-space: normal; } }

.container {
  flex: 1 1;
  padding: 0 3rem 1rem;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .container {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }
  @media only screen and (max-width: 639px) {
    .container {
      padding-left: 1rem;
      padding-right: 1rem; } }

.list {
  display: inline-flex;
  flex-direction: column; }
  .list--expanded {
    width: 100%; }

.footer {
  padding: 2rem 3rem 6rem; }
  @media only screen and (max-width: 767px) {
    .footer {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem; } }
  @media only screen and (max-width: 639px) {
    .footer {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .footer__action {
    display: flex;
    justify-content: space-between; }
  .footer__to-order {
    display: flex; }
  .footer__error {
    margin-right: 2rem;
    color: #5f5f5f; }
  .footer__terms {
    margin-top: 2.5rem;
    font-size: 0.9rem; }
    .footer__terms a {
      color: #0063c8;
      color: var(--main-color, #0063c8); }
      .footer__terms a:hover {
        opacity: 0.8; }

.list-item--sub-1 {
  margin-left: 30px; }

.list-item--sub-2 {
  margin-left: 60px; }

.list-item--sub-3 {
  margin-left: 90px; }

.list-item--sub-4 {
  margin-left: 120px; }

.list-item--sub-5 {
  margin-left: 150px; }

.list-item--sub-6 {
  margin-left: 180px; }

.list-item--sub-7 {
  margin-left: 210px; }

.list-item--sub-8 {
  margin-left: 240px; }

.list-item--sub-9 {
  margin-left: 270px; }

.list-item--sub-10 {
  margin-left: 300px; }

.list-item + .list-item {
  margin-top: 1rem; }

.list-filter {
  display: flex;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 767px) {
    .list-filter {
      flex-direction: column;
      margin-bottom: 1rem; } }
  .list-filter > div {
    margin-right: 1rem; }
    @media only screen and (max-width: 767px) {
      .list-filter > div {
        margin-right: 0;
        margin-bottom: 2rem; } }

.calendar {
  z-index: 9;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  padding: 4px;
  border: 0;
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.1); }
  .calendar__tile:not(.react-calendar__tile--active):not(:disabled) {
    background: #e3ffe7; }

.react-calendar__tile--active {
  color: #fff;
  background: #1087ff;
  background: var(--main-color, #1087ff); }

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
  background: var(--main-color, #1087ff);
  opacity: 0.9; }

.columns {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  justify-content: space-between;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .columns {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .columns {
      grid-template-columns: repeat(4, 1fr); } }
  @media only screen and (max-width: 767px) {
    .columns {
      grid-template-columns: repeat(3, 1fr); } }
  @media only screen and (max-width: 639px) {
    .columns {
      grid-template-columns: repeat(3, 1fr); } }

.column-item {
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center; }
  .column-item.is-disabled {
    background: #ebebeb; }

.dateAndDoctor {
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .dateAndDoctor {
      grid-template-columns: 1fr; } }
  @media only screen and (max-width: 767px) {
    .dateAndDoctor {
      grid-template-columns: 1fr; } }
  @media only screen and (max-width: 639px) {
    .dateAndDoctor {
      grid-template-columns: 1fr; } }

.doctors {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  justify-content: space-between;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content; }
  @media only screen and (max-width: 767px) {
    .doctors {
      grid-template-columns: 1fr; } }
  @media only screen and (max-width: 639px) {
    .doctors {
      grid-template-columns: 1fr; } }

.doctor {
  padding: 10px;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-areas: "img name" "img spec" "img desc";
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 1rem;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }
  .doctor__photo {
    grid-area: img;
    width: 150px;
    height: 150px;
    background: rgba(0, 0, 0, 0.05);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    .doctor__photo img {
      width: 150px; }
  .doctor__name {
    grid-area: name;
    font-size: 1.2rem; }
  .doctor__specializations {
    grid-area: spec;
    margin-top: 0.8rem; }
  .doctor__description {
    grid-area: desc;
    margin-top: 0.8rem;
    font-size: 0.7rem;
    color: #b5b5b5;
    white-space: pre-wrap; }
    .doctor__description a {
      margin-left: 5px;
      color: #0063c8;
      color: var(--main-color, #0063c8); }
  .doctor--active {
    border-left: 3px solid #0063c8;
    border-left: 3px solid var(--main-color, #0063c8); }
  .doctor:hover {
    box-shadow: 0 2px 2px 0 rgba(43, 61, 78, 0.2); }

.details {
  margin: 2rem 0 2rem;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .details {
      flex-direction: column; } }
  .details__row {
    position: relative;
    margin-right: 3rem; }
    @media only screen and (max-width: 767px) {
      .details__row {
        margin-right: 0;
        margin-bottom: 1rem; } }
    .details__row--main {
      flex: 1 1; }
  .details__label {
    font-size: 0.9rem;
    color: #686868; }
  .details__data {
    margin-top: 0.5rem;
    font-size: 1.1rem; }
    .details__data--nowrap {
      white-space: nowrap; }

.content-columns {
  display: flex; }

.message {
  margin-top: 2rem;
  font-size: 1.1rem; }

.result {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: #00836d; }

